<template>
  <div class="pa-4 select-groups">
    <div>
      <img
        width="20px"
        class="cursor-pointer"
        :src="require('@/assets/images/icons/arrow-left-black.svg')"
        @click="submitHandler"
      />

      <!-- templates -->
      <div class="mt-3">
        <div class="px-4 custom-group over-scroll">
          <div
            class="d-flex align-center justify-space-between px-3 radius-15 py-2 mb-2"
            v-for="(group, i) in itemsGroups"
            :key="i"
            :class="group.selected ? 'lightprimary' : 'bg-grey'"
          >
            <span class="font-16 group-name"
              >{{ group.name }} ({{ group.products.length }}) |
              <span class="theme-name">
                {{
                  fetchedThemes.find(
                    (theme) =>
                      theme.numericId == group.theme.numericId ||
                      theme._id == group.theme._id
                  ).name
                }}
              </span>
            </span>

            <div
              class="px-3 pb-1 radius-15 cursor-pointer"
              :class="group.selected ? 'primary' : 'darkgrey'"
              @click="selectGroup('itemsGroups', group)"
            >
              <span
                class="font-14"
                :class="group.selected ? 'white--text' : 'black--text'"
                >{{ group.selected ? "Selected" : "select" }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <v-btn
        block
        height="40px"
        color="primary"
        class="radius-10 mt-5"
        @click="submitHandler"
        elevation="0"
      >
        <span class="font-18 font-500 white--text ml-2">Done</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    itemsGroups: [],
    templatesGroups: [],
  }),
  computed: {
    ...mapGetters(["themes"]),
  },
  methods: {
    selectGroup(type, selectedGroup) {
      this[type].forEach((group) => {
        if (group._id == selectedGroup._id) {
          group.selected = !group.selected;
        }
      });
    },
    submitHandler() {
      let groups = [];
      this.itemsGroups.forEach((group) => {
        if (group.selected) {
          group.isTemplate = false;
          groups.push(group);
        }
      });
      this.templatesGroups.forEach((group) => {
        if (group.selected) {
          group.isTemplate = true;
          groups.push(group);
        }
      });
      this.$emit("selectedGroups", groups);
    },
  },
  async created() {
    this.templatesGroups = [...this.templates];
    this.itemsGroups = [...this.items];
    //console.log("templatesGroups", this.templatesGroups);
  },
};
</script>

<style lang="scss">
.theme-name {
  background: rgb(1 149 169 / 20%);
  border-radius: 10px;
  padding: 7px 10px;
  margin: 0 5px;
  font-weight: 500;
}
.select-groups {
  .custom-group {
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;

    .group-name {
      line-height: 1.8;
    }
  }
}
</style>
