<template>
  <div class="share-code px-5 py-3">
    <div class="d-flex align-center justify-space-between pt-3">
      <span class="font-22"></span>
      <img
        width="14px"
        height="14px"
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>

    <div class="">
      <!-- <div
        class="share-code__copy d-inline-flex align-center px-7 pb-2 pt-3 cursor-pointer"
         @click="copyHandler"
      >
        <img
          class="mr-2"
          :src="require('@/assets/images/icons/copy-icon.svg')"
          alt=""
        />
        <span class="font-16 font-500 black--text">Copy Link</span>
      </div> -->
    </div>
    <!-- <div class="line"></div> -->
    <div class="share-code my-2 d-flex justify-center align-center">
      <div class="code d-inline-flex white">
        <div
          class="primary py-2 px-3 cursor-pointer d-flex justify-center align-center"
          @click="copyHandler"
        >
          <img
            :src="require('@/assets/images/icons/code-icon.svg')"
            alt=""
            width="40px"
            height="40px"
          />
        </div>
        <div
          class="px-4 py-2 border-top-bottom-right"
          style="font-size: 30px; line-height: 1.8"
        >
          <!-- 22 33 44 -->
          <span @copy="copyHandler">{{
            code.split("").reduce((acc, curr, i) => {
              if (i % 2 === 0) {
                return acc + curr;
              }
              return acc + curr + " ";
            }, "")
          }}</span>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="d-flex align-center">
        <span class="greytext--text font-500 cursor-pointer">How to use? </span>
      </div>
      <transition name="fade" mode="out-in">
        <p class="font-20 mr-14 pr-10" :key="open">
          Visit our website
          <router-link to="/code" class="font-500 primary--text cursor-pointer"
            >ofero.co/tv</router-link
          >
          and put the code <span class="font-600">inside code generator</span>
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    open: false,
    code: "",
  }),
  methods: {
    async copyHandler() {
      // const el = document.createElement("textarea");
      // el.addEventListener("focusin", (e) => e.stopPropagation());
      // el.value = this.item.code.replace(/ /g, "");
      // document.body.appendChild(el);
      // el.select();
      // document.execCommand("copy");
      // document.body.removeChild(el);

      await navigator.clipboard.writeText(this.item.code.replace(/ /g, ""));
      this.$store.dispatch("showSnack", {
        text: "Code has been copied to your clipboard",
        color: "success",
      });
    },
  },
  created() {
    this.code = this.item.code;
  },
};
</script>

<style lang="scss">
.share-code {
  &__copy {
    border: 1px solid black;
    border-radius: 15px;
  }
  .line {
    width: 150px;
    height: 1px;
    margin: 20px 0px;
    background: rgba(0, 0, 0, 0.2);
  }
  .copy-arrow {
    transition: 0.3s;
    transform: rotate(180deg);

    &--active {
      transform: rotate(0deg);
    }
  }
  .code {
    border-radius: 15px;
    overflow: hidden;
  }
}
.border-top-bottom-right {
  border: 1px solid;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}
</style>
