<template>
  <div :class="[item.isRotated ? `rotate-${item.rotation}` : 'no-rotate']">
    <div class="text-center relative">
      <div class="d-flex align-center justify-space-between close-icon">
        <span class="font-22"></span>
        <img
          width="14px"
          height="14px"
          @click="$emit('close')"
          :src="require('@/assets/images/icons/close.svg')"
          class="cursor-pointer"
        />
      </div>
      <!-- items -->
      <div class="w-100" v-if="groups.length">
        <div style="width: 100%; height: 100vh" class="wrapper-layout">
          <!-- :key="themeChangedKey" -->
          <theme-layout
            :key="themeChangedKey"
            :products="
              filterProducts(groups[activeGroupIndex].groupId.products)
            "
            :logo="logo"
            :currentGroup="groups[activeGroupIndex].groupId"
            :currency="currencySymbol"
            :active-theme="groups[activeGroupIndex].groupId.theme"
            :next-group-trigger="nextGroupTrigger"
            @goNext="goNextHandler"
          ></theme-layout>
        </div>
      </div>
      <div v-else class="no-groups">
        <h1>No groups to show!</h1>
      </div>
    </div>

    <div
      v-if="fitTextLoader"
      class="loader-overlay d-flex align-center justify-center"
      :style="`background-color: ${groups[activeGroupIndex].groupId.theme.container.color}`"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import ThemeLayout from "@/components/themes/ThemeLayout.vue";
import LogoOverlay from "@/components/dialogs/LogoOverlay.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  components: {
    ThemeLayout,
  },
  data: () => ({
    MenuSlidesActive: false,
    groups: [],
    themeNumber: 1,
    themeNumberTemplate: 1,
    logo: "",
    activeGroupIndex: 0,
    themeChangedKey: false,
    nextGroupTrigger: Math.random(),
  }),
  computed: {
    currencySymbol() {
      return this.$store.state.profile?.currency?.symbol ?? "$$";
    },
    ...mapGetters(["showLogoLayer", "ActiveScreenWidth"]),
  },
  methods: {
    filterProducts(products) {
      return products.filter((product) => {
        return product && !product?.item?.deleted && product?.item?.active;
      });
    },
    async goNextHandler() {
      // always fetch again to check for any updates
      await this.getItems();

      // only trigger the goNext logic if there are more than one group
      if (this.groups.length > 1) {
        if (this.activeGroupIndex >= this.groups.length - 1) {
          this.activeGroupIndex = 0;
        } else {
          this.activeGroupIndex += 1;
        }
      } else {
        this.activeGroupIndex = 0;
      }
      this.themeChangedKey = !this.themeChangedKey;

      // because here we fetch the data always, we will always send a nextGroupTrigger
      this.nextGroupTrigger += Math.random();
    },
    goNextHandlerWithoutFetching(updatedGroups) {
      // this method will only be called once there are any updates in the groups
      // it's triggered after fetching the data from the active theme running and comparing it with the current state

      // set the new groups
      this.groups = updatedGroups;

      // only trigger the goNext logic if there are more than one group
      if (updatedGroups.length > 1) {
        // when reached the last group, go to the first group
        if (this.activeGroupIndex >= this.groups.length - 1) {
          this.activeGroupIndex = 0;
        } else {
          this.activeGroupIndex += 1;
        }
      } else {
        // if there is only one group, make sure the active group is the first
        this.activeGroupIndex = 0;
      }

      // reset the theme changed key
      this.themeChangedKey = !this.themeChangedKey;
    },
    async getItems() {
      let { data } = await this.$axios.get(
        `${process.env.VUE_APP_PREVIEWS}/${this.item._id}`
      );
      if (data) {
        this.$store.dispatch("cleanFitTextCache");
        this.groups = data.preview.groups.filter((group) => {
          let outOfRange = false;

          if (group.groupId.startDate && group.groupId.endDate) {
            outOfRange = this.isOutOfDateRange(
              group.groupId.startDate,
              group.groupId.endDate
            );
          }

          return (
            group.groupId.active &&
            group.groupId.products.length > 0 &&
            !outOfRange
          );
        });
        this.logo = data.preview.showLogo ? data.logo.url : "";
      }
    },
  },
  mounted() {
    let groups = this.item.groups.filter((group) => {
      // let outOfRange = false;

      // if (group.groupId.startDate && group.groupId.endDate) {
      //   outOfRange = this.isOutOfDateRange(
      //     group.groupId.startDate,
      //     group.groupId.endDate
      //   );
      // }

      return (
        // group.groupId.active && group.groupId.products.length > 0 && !outOfRange
        group.groupId.active && group.groupId.products.length > 0
      );
    });

    this.groups = groups;

    this.logo = this.item.showLogo ? this.item.logo.url : "";
  },
  beforeDestroy() {
    this.$store.dispatch("cleanFitTextCache");
  },
};
</script>

<style lang="scss">
.enter-code {
  .code {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;
  }

  .btn {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.no-groups {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
