<template>
  <div class="preview">
    <div class="max-width">
      <span class="font-22 font-600 d-block pl-2">Screens</span>
      <span
        @click="newDialogue"
        class="font-30 d-inline-block px-3 white--text primary ml-2 radius-5 mt-3 mb-5 cursor-pointer"
        >+</span
      >
      <v-row class="px-2">
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="(preview, i) in previews"
          :key="i"
          class="relative"
        >
          <v-icon
            class="preview__delete"
            @click="deleteHandler(preview)"
            color="error"
            >mdi-delete</v-icon
          >

          <img
            v-if="preview.isPair"
            class="broadcast-icon"
            src="@/assets/images/icons/broadcast.svg"
            alt=""
          />
          <img
            v-else
            class="broadcast-icon"
            src="@/assets/images/icons/broadcast-active.svg"
            alt=""
          />

          <div class="preview__card">
            <div>
              <span class="font-20 font-500">{{ preview.title }}</span>
            </div>
            <v-btn
              block
              height="40px"
              color="primary"
              class="radius-10 mt-7"
              @click="openScanHandler(preview)"
              elevation="0"
              v-if="isMobile"
            >
              <span class="font-18 font-500 white--text ml-2">Scan</span>
            </v-btn>
            <!-- <v-btn
              block
              color="primary"
              height="40px"
              class="radius-10 mt-1"
              @click="EditHandler(preview)"
              elevation="0"
            >
              <v-switch
                class="mt-0 mr-0"
                :hide-details="true"
                v-model="preview.showLogo"
                inset
                color="white"
              ></v-switch>
              <span class="font-18 font-500 white--text ml-2"
                >Display logo</span
              >
            </v-btn> -->

            <v-tooltip bottom color="white">
              <template v-slot:activator="{ on, attrs }">
                <div class="py-3 text-start">
                  <span v-on="on" v-bind="attrs" class="connection-hint"
                    >How to connect?</span
                  >
                </div>
              </template>
              <span class="black--text position-relative"
                >Visit <span class="font-600">ofero.co/tv</span> on the device
                you wish to turn into a sign. Then come back here and enter the
                code shown.</span
              >
            </v-tooltip>

            <div class="pair-wrapper">
              <v-icon color="white" size="large">mdi-cast-connected</v-icon>
              <div>
                <v-text-field
                  :hide-details="true"
                  background-color="transparent"
                  v-model="preview.displayCode"
                  class="input-pair"
                  solo
                  flat
                  placeholder="_ _ _ _"
                >
                </v-text-field>
              </div>
              <v-btn
                height="40px"
                color="white"
                min-width="30%"
                class="radius-10"
                :ripple="false"
                @click="preview.isPair ? unPair(preview) : pair(preview)"
              >
                {{ preview.isPair ? "unPair" : "Pair" }}
              </v-btn>
            </div>

            <div class="d-flex align-center justify-center mt-2">
              <!-- edit  -->

              <v-btn
                width="50%"
                height="40px"
                color="white"
                class="radius-10 mx-1"
                @click="editHandler(preview)"
                elevation="0"
              >
                <span class="font-18 font-500 black--text ml-2">Edit</span>
              </v-btn>

              <v-btn
                width="50%"
                height="40px"
                color="white"
                class="radius-10 mx-1"
                elevation="0"
                @click="handlePreview(preview)"
                :disabled="noProducts(preview)"
              >
                <img :src="require('@/assets/images/icons/eye-black.svg')" />
                <span class="font-18 font-500 black--text ml-2">Preview</span>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="text-center mt-2" v-if="previews.length == 0">
      <span>No Screens Yet...</span>
    </div>

    <!-- add/edit new -->
    <v-dialog
      v-model="newDialog"
      max-width="600px"
      content-class="white"
      :key="Math.random() * 1000"
    >
      <custom-preview
        @close="newDialog = false"
        :isEdit="isEdit"
        :key="newDialog"
        :currentPreview="selectedItem"
        @update="getPreviews"
      ></custom-preview>
    </v-dialog>

    <!-- add/edit new -->
    <v-dialog v-model="shareDialog" max-width="500px" content-class="white">
      <share-code
        @close="shareDialog = false"
        :key="Math.random() * 1000"
        :item="selectedItem"
      ></share-code>
    </v-dialog>

    <!-- add/edit new -->
    <v-dialog
      v-model="previewDialog"
      fullscreen
      content-class="white overflow-hidden border-radius-0"
      :key="Math.random() * 1000"
    >
      <preview-dialog
        v-if="selectedItem._id"
        @close="previewDialog = false"
        :key="Math.random() * 1000"
        :item="selectedItem"
      ></preview-dialog>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <custom-delete
        @close="deleteDialog = false"
        @getData="getPreviews"
        :urlApi="previewsUrl"
        :itemID="selectedItem._id"
      ></custom-delete>
    </v-dialog>
    <v-dialog v-model="scanDialogue" width="500px" content-class="white">
      <div v-if="scanDialogue">
        <StreamBarcodeReader
          v-if="scanDialogue"
          @decode="onDecode"
        ></StreamBarcodeReader>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomPreview from "../../components/dialogs/CustomPreview.vue";
import PreviewDialog from "../../components/dialogs/PreviewDialog.vue";
import ShareCode from "../../components/dialogs/ShareCode.vue";

import { StreamBarcodeReader } from "vue-barcode-reader";
import NoSleep from "nosleep.js";
export default {
  components: {
    CustomPreview,
    ShareCode,
    PreviewDialog,
    StreamBarcodeReader,
  },
  data() {
    return {
      fullscreen: false,
      themeActive: false,
      newDialog: false,
      shareDialog: false,
      previewDialog: false,
      isEdit: false,
      deleteDialog: false,
      scanDialogue: false,
      themeNumber: 1,
      previews: [],
      selectedItem: {},
      connections: {},
    };
  },
  computed: {
    previewsUrl() {
      return process.env.VUE_APP_PREVIEWS;
    },
  },
  watch: {
    scanDialogue: {
      async handler() {
        if (!this.scanDialogue) {
          window.stopRecord();
        }
      },
    },
  },
  methods: {
    async openScanHandler(item) {
      this.scanDialogue = true;
      this.selectedItem = item;
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
    },
    newDialogue() {
      this.newDialog = true;
      this.isEdit = false;
      this.selectedItem = {};
      //console.log(this.isEdit);
    },
    noProducts(preview) {
      return !preview.groups.reduce((a, b) => {
        return a + b.groupId.products.length;
      }, 0);

      // let i = 0;
      // var num = 0;
      // for (i = 0; i < preview.groups.length; i++) {
      //   num = num + preview.groups[i].groupId.products.length;
      // }
      // if (num == 0) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    onDecode() {
      this.$router.replace("/code/" + this.selectedItem.code);
    },
    async deleteHandler(item) {
      this.deleteDialog = true;
      this.selectedItem = item;
    },
    async handlePreview(item) {
      // window.open('https://ofero.co/'+item.code)
      // this.previewDialog = true;
      // this.$router.replace('/code/' + item.code)
      let { data } = await this.$axios.get(`${this.previewsUrl}/${item._id}`);
      if (data) {
        this.previewDialog = true;
        // console.log("preview Data", data);
        this.selectedItem = {
          ...data.preview,
          logo: data.logo,
        };
      }
    },
    shareHandler(item) {
      this.selectedItem = item;
      this.shareDialog = true;
    },
    editHandler(item) {
      this.newDialog = true;
      this.isEdit = true;
      this.selectedItem = item;
    },
    EditHandler(item) {
      //console.log(item);
      this.$axios
        .patch(`${this.previewsUrl}/${item._id}`, {
          showLogo: item.showLogo,
        })
        .then(() => {
          this.$store.dispatch("showSnack", {
            text: "Updated Successfully",
            color: "success",
          });
          this.getPreviews();
        });
    },
    async getPreviews() {
      this.newDialog = false;
      let { data } = await this.$axios.get(this.previewsUrl);
      if (data) {
        this.previews = data.data.map((item) => {
          return {
            ...item,
            connectionId: "",
          };
        });
      }
    },
    pair(preview) {
      this.$axios
        .post(process.env.VUE_APP_PAIR, {
          code: preview.displayCode,
          screenId: preview._id,
        })
        .then((res) => {
          this.$store.dispatch("showSnack", {
            text: "Paired Successfully",
            color: "success",
          });

          this.getPreviews();
        })
        .catch((err) => {
          this.$store.dispatch("showSnack", {
            text: err.error,
            color: "error",
          });
        });
    },
    unPair(preview) {
      this.$axios
        .delete(process.env.VUE_APP_PAIR, {
          data: {
            screenId: preview._id,
          },
        })
        .then(() => {
          this.$store.dispatch("showSnack", {
            text: "Unpaired Successfully",
            color: "success",
          });
          this.getPreviews();
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("showSnack", {
            text: err.error,
            color: "error",
          });
        });
    },
  },
  async created() {
    await this.getPreviews();
  },
  mounted() {
    let noSleep = new NoSleep();
    noSleep.enable();
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
.preview {
  padding-block: 30px;

  .broadcast-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }

  &__card {
    background: #f2f2f2;
    border-radius: 15px;
    padding: 36px 9px 8px 9px;
    text-align: center;
    position: relative;
  }

  &__delete {
    position: absolute !important;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 9;
  }

  @media (max-width: 700px) {
    padding: 50px 10px;
  }
}

.slick-track {
  margin: auto;
}

.connection-hint {
  background: white;
  color: #000;
  border-radius: 10px;
  border: 1px solid transparent;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: default;

  &:hover {
    border-color: $primary;
  }
}

.v-tooltip__content {
  opacity: 1 !important;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  max-width: 300px;

  span.text-black {
    color: black;
    font-weight: 600;
  }
}

.pair-wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background: $primary;
  border-radius: 10px;
  .v-btn__content {
    font-size: 18px;
    font-weight: 500;
  }
  .input-pair {
    .v-input__slot::before {
      border-style: none !important;
    }
    .v-input--is-focused .v-input__slot {
      color: #cb2323 !important;
    }

    input,
    input::placeholder {
      color: #ffffff !important;
    }
    width: 100%;
    // margin-left: 10px;
    background: transparent;
    border: none !important;
    outline: none !important;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
}
</style>
