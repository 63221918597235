<template>
  <div class="pa-4 layer">
    <div v-if="logo" class="wrapper-image animate__animated animate__zoomIn">
      <img :src="require('@/assets/images/global/logo.svg')" alt="logo" />
    </div>

    <div v-if="!logo" class="spinner">
      <PulseLoader color="#0195A9"></PulseLoader>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import 'animate.css';

export default {
  props: {
    logo: {
      type: String,
      default: "",
      required: false,
    },
  },
  components: {
    PulseLoader,
  },
};
</script>

<style lang="scss" scoped>
.layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7000;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.wrapper-image {
  width: 300px;
  height: 300px;
  border-radius: 47px;
  //background-color: rgba(0, 0, 0, 1);
  & img {
    width: 100%;
    height: 100%;
  }
}
.spinner {
  width: 100%;
  text-align: center;
}
</style>
