<template>
  <div>
    <div v-if="!selectGroups">
      <div
        class="d-flex align-center justify-space-between px-8 py-3 darkbg-grey"
      >
        <span class="font-22">Custom Screen</span>
        <img
          @click="closeDialogue"
          :src="require('@/assets/images/icons/close.svg')"
          class="cursor-pointer"
        />
      </div>
      <div class="pa-3 px-8">
        <v-text-field
          v-model="preview.title"
          label="Screen Title"
          dense
          class="my-5"
          :hide-details="true"
        ></v-text-field>
        <v-btn
          width="100%"
          class="radius-10 mb-3 mt-3"
          height="40px"
          color="#E1E1E1"
          @click="selectGroups = true"
          elevation="0"
        >
          <span class="font-18 font-500 black--text">Select Groups</span>
        </v-btn>

        <draggable
          @change="orderSortHandler"
          v-model="items"
          group="people"
          style="display: contents"
          class="row mt-4 justify-start"
        >
          <div
            class="mb-2"
            :class="preview.expired && false ? 'expired-group' : ''"
            v-for="(item, i) in items"
            :key="i"
          >
            <div
              class="py-3 px-4 radius-10 d-flex align-center justify-space-between"
              :class="item.isTemplate ? 'lightprimary' : 'bg-grey'"
            >
              <span class="d-block font-16">
                {{ item.name }} ({{ item.products.length }}) |
                <span class="theme-name">
                  {{
                    fetchedThemes.find(
                      (theme) =>
                        theme.numericId === item.theme.numericId ||
                        theme._id === item.theme._id
                    ).name
                  }}
                </span>
              </span>
              <!-- <span>{{ item.expired ? "Group will not show" : "" }}</span> -->
              <img
                class="cursor-pointer"
                :src="require('@/assets/images/icons/drag-icon.svg')"
                alt=""
              />
            </div>
          </div>
        </draggable>
        <!-- <div class="d-flex align-center mt-10">
          <span class="d-inline-block mr-3 font-22">Show your logo</span>
          <v-switch class="mt-0 mr-0" :hide-details="true" v-model="item.showLogo" inset></v-switch>
        </div>
        <div class="d-flex align-center mt-6">
          <span class="d-inline-block mr-3 font-22">Featured</span>
          <v-switch class="mt-0 mr-0" :hide-details="true" v-model="featured" inset></v-switch>
        </div> -->
        <!-- <div>
          <p>Rotate</p>
          <v-switch v-model="preview.isRotated" :label="'Rotate'"></v-switch>
        </div>
        <v-radio-group v-model="preview.rotation" row v-if="preview.isRotated">
          <v-radio label="90 degree" value="90"></v-radio>
          <v-radio label="180 degree" value="180"></v-radio>
          <v-radio label="270 degree" value="270"></v-radio>
        </v-radio-group> -->
        <v-btn
          block
          height="40px"
          color="primary"
          class="radius-10 mt-3"
          :loading="loading"
          :disabled="loading"
          @click="submitHandler"
          elevation="0"
        >
          <span class="font-18 font-500 white--text ml-2">Done</span>
        </v-btn>
      </div>
    </div>

    <!-- select  -->
    <select-groups
      v-if="selectGroups"
      @back="backHandler"
      @selectedGroups="selectedGroupsHandler"
      :items="itemsGroups"
      :templates="templatesGroups"
    ></select-groups>
  </div>
</template>

<script>
import SelectGroups from "./SelectGroups.vue";
import { mapGetters } from "vuex";
export default {
  components: { SelectGroups },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    currentPreview: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    preview: {},
    items: [],
    title: "",
    featured: false,
    selectGroups: false,
    loading: false,
    itemsGroups: [],
    templatesGroups: [],
    // rotate: false,
    // rotateDegree: 90,
  }),
  computed: {
    ...mapGetters(["themes"]),
  },
  async mounted() {
    await this.getGroupsItems();
    await this.getGroupsTemplates();
    if (this.isEdit) {
      this.preview = { ...this.currentPreview };

      this.preview.groups?.forEach((currentPrevGroup) => {
        //console.log( this.preview.groups,777);

        // if (currentPrevGroup.groupId.type === "template") {
        //   this.templatesGroups.forEach((templateGroup) => {
        //     if (currentPrevGroup.groupId._id == templateGroup._id) {
        //       templateGroup.selected = true;
        //       templateGroup.isTemplate = true;
        //       this.items.push(templateGroup);
        //     }
        //   });
        // } else {
        this.itemsGroups.forEach((itemGroup) => {
          if (currentPrevGroup.groupId._id == itemGroup._id) {
            itemGroup.selected = true;
            itemGroup.isTemplate = false;
            this.items.push(itemGroup);
          }
        });
        // }
      });
    }
  },
  methods: {
    orderChangeHandler() {},
    selectGroup() {},
    backHandler() {
      this.selectGroups = false;
    },
    closeDialogue() {
      this.$emit("close");
    },
    async getGroupsItems() {
      let { data } = await this.$axios.get(
        `${process.env.VUE_APP_GROUPS}?acceptempty=true&isdeleted=false&ispdeleted=false`
      );
      if (data.success) {
        this.itemsGroups = data.Categories.map((group) => {
          return { ...group, selected: false };
        });
      }
    },
    async getGroupsTemplates() {
      let { data } = await this.$axios.get(process.env.VUE_APP_PRODUCTS);
      if (data.success) {
        this.templatesGroups = data.Categories.filter(
          (item) => !item.deleted
        ).map((group) => {
          return { ...group, selected: false };
        });
      }
    },
    selectedGroupsHandler(value) {
      this.selectGroups = false;
      this.items = value;
    },
    async submitHandler() {
      this.loading = true;
      let obj = {
        groups: this.items.map((item, i) => ({
          id: item._id,
          sort: String(i),
        })),
        title: this.preview.title,
        showlogo: this.preview.showLogo,
        featured: this.featured,
        isRotated: this.preview.isRotated,
        rotation: this.preview.rotation,
      };

      if (this.isEdit) {
        this.$axios
          .patch(`${process.env.VUE_APP_PREVIEWS}/${this.preview._id}`, obj)
          .then((res) => {
            this.loading = false;
            this.$store.dispatch("showSnack", {
              text: `Updated Successfully`,
              color: "success",
            });
            this.$emit("update");
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
            this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong!",
              color: "error",
            });
          });
      } else {
        console.log("add...");
        this.$axios
          .post(process.env.VUE_APP_PREVIEWS, obj)
          .then((res) => {
            this.loading = false;
            this.$store.dispatch("showSnack", {
              text: `Added Successfully`,
              color: "success",
            });
            this.$emit("update");
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
            if (err.message === "limit exceeded") {
              this.$store.dispatch("setProTipDialog", {
                show: true,
                title: "You've Reached Your Limit!",
                description: "Don't Let Limits Hold You Back.",
              });
            } else {
              this.$store.dispatch("showSnack", {
                text: err.message || err.error || "Something went wrong!",
                color: "error",
              });
            }
          });
      }
    },
    async orderSortHandler() {
      // if (this.preview._id) {
      //   let sortObj = {
      //     groupsIds: this.items.map((item) => item._id),
      //   };
      //   await this.$axios.post(
      //     `${process.env.VUE_APP_PREVIEWS}/${this.preview._id}/sort`,
      //     sortObj
      //   );
      // }
    },
  },
};
</script>

<style lang="scss">
.expired-group {
  opacity: 0.7;

  img {
    filter: grayscale(1);
    cursor: no-drop;
  }
}
</style>
